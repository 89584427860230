<template>
  <div v-if="broadcast" class="broadcast-section">
    <WizardForm v-if="broadcast" :broadcast="broadcast" @broadcast-changed="broadcastChanged"></WizardForm>
  </div>
</template>

<script>
import WizardForm from './WizardForm'
import moment from 'moment-timezone'
import Vue from 'vue'

export default {
  components: {
    WizardForm,
  },

  props: {
    object: {
      type: Object,
      default: null,
    }
  },

  data() {
    return {
      broadcast: null,
      defaultBroadcast: {
        message: '',
        keywords: [],
        automations: [],
        send_type: 'now',
        name: null,
        date: moment().format('YYYY-MM-DD'),
        time: '08:00',
        timezone: moment.tz.guess(),
        images: [],
        exclude_keywords: [],
        provider: 'sms',
        options: {
          width: 750,
          align: 'center'
        },
      },
      constBroadcast: null,
    }
  },

  computed: {
    user() {
      return this.$store.getters['auth/user']
    },
  },

  mounted() {
    if (this.object) {
      this.broadcast = JSON.parse(JSON.stringify(this.object))
    } else {
      this.broadcast = JSON.parse(
        JSON.stringify(this.defaultBroadcast)
      )

      this.broadcast = Vue.util.extend({}, this.defaultBroadcast);
      this.broadcast.provider = this.$route.query.type ? this.$route.query.type : 'sms'
    }

    this.constBroadcast = JSON.parse(JSON.stringify(this.broadcast))
  },

  methods: {
    broadcastChanged(broadcast) {
      this.broadcast = broadcast
      this.constBroadcast = JSON.parse(JSON.stringify(broadcast))
    },

    beforeRouteLeave(to, from, next) {
      let diff = this.difference(this.broadcast, this.constBroadcast)

      if (Object.keys(diff).length) {
        const answer = window.confirm('Are you sure you want to leave without saving?')
        if (answer) {
          next()
        } else {
          next(false)
        }
      } else {
        next()
      }
    }
  },
}
</script>
